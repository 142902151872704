import product from "../../assets/eventposter.jpg"

export const EventsJson = [
    {
        id: 1,
        title: [
            "Paper Presentation",
            "Poster Presentation",
            "Project Exhibition  ",
            "Quiz Activity",
            "Culturals"
        ],
        price: [
            {
                amount: "₹ 100",

            },
            {
                amount: "₹ 100",

            },
            {
                amount: "₹ 100",

            },
            {
                amount: "₹ 100",

            },
            {
                amount: "Free",

            }
        ],
        imgSrc: product,
    },
];

