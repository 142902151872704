import product from "../../images/product3.jpg";


//Events
//foods
import fd1 from "../../images/food/fd1.jpg";
import fd3 from "../../images/food/fd3.jpg";
import fd4 from "../../images/food/fd4.jpg";
import fd5 from "../../images/food/fd5.jpg";
import fd6 from "../../images/food/fd6.jpg";
import fd7 from "../../images/food/fd7.jpg";
import fd8 from "../../images/food/fd8.jpg";
import fd9 from "../../images/food/fd9.jpg";

//inagration
import i1 from "../../images/inagration/i1.jpg";
import i2 from "../../images/inagration/i2.jpg";
import i3 from "../../images/inagration/i3.jpg";
import i4 from "../../images/inagration/i4.jpg";
import i5 from "../../images/inagration/i5.jpg";
import i6 from "../../images/inagration/i6.jpg";
import i7 from "../../images/inagration/i7.jpg";
import i8 from "../../images/inagration/i8.jpg";
import i9 from "../../images/inagration/i9.jpg";

//falshmob
import f1 from "../../images/flashmob/f1.jpg";
import f2 from "../../images/flashmob/f2.jpg";
import f3 from "../../images/flashmob/f3.jpg";
import f4 from "../../images/flashmob/f4.jpg";

//culturals
import c1 from "../../images/culturals/c1.jpg";
import c2 from "../../images/culturals/c2.jpg";
import c3 from "../../images/culturals/c3.jpg";
import c4 from "../../images/culturals/c4.jpg";
import c5 from "../../images/culturals/c5.jpg";
import c6 from "../../images/culturals/c6.jpg";
import c7 from "../../images/culturals/c7.jpg";
import c8 from "../../images/culturals/c8.jpg";
import c9 from "../../images/culturals/c9.jpg";


//workshops
import w1 from "../../images/workshops/w1.jpg";
import w2 from "../../images/workshops/w2.jpg";
import w3 from "../../images/workshops/w3.jpg";
import w4 from "../../images/workshops/w4.jpg";
import w5 from "../../images/workshops/w5.jpg";
import w6 from "../../images/workshops/w6.jpg";
import w7 from "../../images/workshops/w7.jpg";








export const galleryData = [
    {
        id: 1,
        title: "Inauguration",
        images: [i1, i2, i3, i4, i5, i6, i7, i8, i9]
    }
    ,
    {
        id: 2,
        title: "Workshops",
        images: [w1, w2, w3, w4, w5, w6, w7]
    },
    {
        id: 4,
        title: "Events Culturals and Flashmob",
        images: [f1, f2, f3, f4, c1, c2, c9, c3, c4, c5, c6, c7, c8,]
    },
    {
        id: 3,

        title: "Food",
        images: [fd1, fd3, fd4, fd5, fd6, fd7, fd8, fd9]
    },

    /*  {
         id: 5,
         title: "Processes",
         images: [product, product, product, product]
     },
     {
         id: 6,
         title: "Prototypes",
         images: [product, product, product, product]
     } */
];
